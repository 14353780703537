import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>

        <p>Hello this is prnaav Kumar</p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

function Intiator() {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const fetchDomainSetting = async () => {
    const routes = ["lander-1", "lander-2", "lander-78"]
    const pathname = window.location.pathname.split('/')[1]
    const finalizedPath = routes.find(route => pathname.includes(route));
    const subDomain = window.location.hostname
    const url = `https://backend.subsidyaid.com/sites/production?landerName=${finalizedPath ? finalizedPath : ""}&subDomainName=${subDomain}`
    // const url = "https://backend.subsidyaid.com/sites/production?landerName=&subDomainName=testing.subsidyaid.com"
    if (window.location.hostname.includes('localhost')) {
      var script = window.document.createElement('script');
      script.src = "/scripts/localScript.js";
      script.async = false;
      document.head.appendChild(script);
    }
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
        window.domain_settings = data;
        var script = window.document.createElement('script');
        script.src = "/scripts/scripts.js";
        script.async = false;
        document.head.appendChild(script);
        setScriptLoaded(true)
      })
      .catch(error => {
        setScriptLoaded(true);
        console.error('There was a problem with your fetch operation:', error);
      });
  }

  useEffect(() => {
    fetchDomainSetting();
  }, [])
  if (!scriptLoaded) return null
  return (
    <App />
  )
}

export default Intiator;
